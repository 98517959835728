@font-face {
  font-family: 'Content';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwUaA.ttf) format('truetype');
}
@font-face {
  font-family: 'Content';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/content/v24/zrfg0HLayePhU_AwaRzdBio.ttf) format('truetype');
}
@font-face {
  font-family: 'Suwannaphum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/suwannaphum/v29/jAnCgHV7GtDvc8jbe8hXXIWl.ttf) format('truetype');
}
