@import "antd/dist/antd.less";
@import (inline) '~bootstrap/dist/css/bootstrap.min.css';
@import (inline) '~react-phone-number-input/style.css';
@import url('https://fonts.googleapis.com/css2?family=Moul&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Content:wght@400;700&family=Suwannaphum&display=swap');

body {
  font-family: -apple-system,BlinkMacSystemFont,Content, "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.khmer-moul {
  font-family: -apple-system,BlinkMacSystemFont,Moul, "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
} 

.anticon svg {
  display: flex !important;
}

.content-center-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
  [data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
  }

.container > * {
  box-shadow: none !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: white !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #eff2f7 !important;
  border-radius: 5px !important;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

a {
  color: @link-color;
}

a:hover {
  text-decoration: none !important;
}

.PhoneInput {
  line-height: 1.5715;
  max-height: 32px !important;
}

.PhoneInputInput:hover, .PhoneInputInput:focus {
  border: none !important;
  outline-width: 0 !important;
}

.PhoneInputInput {
  border: 0px !important;
}

h4.ant-typography {
  font-weight: 500 !important;
}
@primary-color: #335e9c;